import React, {Component} from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
import './Projects.css';
import {PROJECTS as COPY} from '../../constants/copy';
import {ProjectsImages} from '../../assets/images';
import Ramona from '../../assets/images/albums/ramona';
import NE from '../../assets/images/albums/ne';
import Chavez from '../../assets/images/albums/chavez';
import SouthernLites from '../../assets/images/albums/lites';
import Windsor from '../../assets/images/albums/windsor';
import Kitchen from '../../assets/images/albums/kitchen';
import Bath from '../../assets/images/albums/bath';

export default class Projects extends Component {
  constructor(props) {
    super(props);
    this.state = {
      photoIndex: 0,
      isOpen: false,
      images: [],
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const { photoIndex, isOpen, images } = this.state;
    return (
      <div className='Projects'>
        <div className='Projects-header-img' style={styles.headerImg}>
          <h2 className='Projects-header-title header-title'>Our work.<br/>Our pride.</h2>
        </div>
        <section className='grid1'>
          <div className='Projects-img ramona' style={styles.ramonaImg} onClick={() => this._openLightbox(Ramona.photos)} />
          <div className='Projects-label-container ramona-label'>
            <h2 className='Projects-title title'>{COPY.ramonaTitle}</h2>
            <p className='Projects-description'>{COPY.ramona}</p>
          </div>
          <div className='Projects-img ne' style={styles.neImg} onClick={() => this._openLightbox(NE.photos)} />
          <div className='Projects-label-container ne-label'>
            <h2 className='Projects-title title'>{COPY.neTitle}</h2>
            <p className='Projects-description'>{COPY.ne}</p>
          </div>
          <div className='Projects-img chavez' style={styles.chavezImg} onClick={() => this._openLightbox(Chavez.photos)} />
          <div className='Projects-label-container chavez-label'>
            <h2 className='Projects-title title'>{COPY.chavezTitle}</h2>
            <p className='Projects-description'>{COPY.chavez}</p>
          </div>
          <div className='Projects-img lites' style={styles.litesImg} onClick={() => this._openLightbox(SouthernLites.photos)} />
          <div className='Projects-label-container lites-label'>
            <h2 className='Projects-title title'>{COPY.litesTitle}</h2>
            <p className='Projects-description'>{COPY.lites}</p>
          </div>
          <div className='Projects-img windsor' style={styles.windsorImg} onClick={() => this._openLightbox(Windsor.photos)} />
          <div className='Projects-label-container windsor-label'>
            <h2 className='Projects-title title'>{COPY.windsorTitle}</h2>
            <p className='Projects-description'>{COPY.windsor}</p>
          </div>
          <div className='Projects-img kitchen' style={styles.kitchenImg} onClick={() => this._openLightbox(Kitchen.photos)} />
          <div className='Projects-label-container kitchen-label'>
            <h2 className='Projects-title title'>{COPY.kitchenTitle}</h2>
            <p className='Projects-description'>{COPY.kitchen}</p>
          </div>
          <div className='Projects-img bathroom' style={styles.bathroomImg} onClick={() => this._openLightbox(Bath.photos)} />
          <div className='Projects-label-container bathroom-label'>
            <h2 className='Projects-title title'>{COPY.bathroomTitle}</h2>
            <p className='Projects-description'>{COPY.bathroom}</p>
          </div>
        </section>
        {isOpen && (
          <Lightbox
            mainSrc={images[photoIndex]}
            nextSrc={images[(photoIndex + 1) % images.length]}
            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: (photoIndex + images.length - 1) % images.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % images.length,
              })
            }
          />
        )}
      </div>
    );
  }

  _openLightbox = (images) => {
    this.setState({ images, photoIndex: 0, isOpen: true });
  }
}

const styles = {
  headerImg: {
    backgroundImage: `url(${ProjectsImages.header})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  ramonaImg: {
    background: `url(${Ramona.photos[0]})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  neImg: {
    background: `url(${NE.photos[0]})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  chavezImg: {
    background: `url(${Chavez.photos[0]})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  litesImg: {
    background: `url(${SouthernLites.photos[0]})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  windsorImg: {
    background: `url(${Windsor.photos[0]})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  kitchenImg: {
    background: `url(${Kitchen.photos[0]})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  bathroomImg: {
    background: `url(${Bath.photos[0]})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
}