import React, {Component} from 'react';
import {FOOTER as COPY} from '../../constants/copy';
import './Footer.css';

export default class Footer extends Component {
  render() {
    return (
      <div style={this.props.styles} className='Footer'>
        {/* <div className='Footer-group row'>
          <p className='privacy-policy'>PRIVACY POLICY</p>
          <p>TERMS OF USE</p>
        </div> */}
        <div className='Footer-group'>
          <p>{COPY.copyright}</p>
        </div>
        <div className='Footer-group Footer-divider'>
          <p>|</p>
        </div>
        <div className='Footer-group'>
          <p>{COPY.info}</p>
        </div>
      </div>
    );
  }
}