import React, {Component} from 'react';
import Quote from '../../components/Quote/Quote';
import {CONTACT as COPY} from '../../constants/copy';
import {ContactImages} from '../../assets/images';
import './Contact.css';

export default class Projects extends Component {

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className='Contact'>
        <div className='navbar-spacer' />
        <div className='Contact-container'>
          <div className='Contact-img' style={styles.image} />
          <div className='Contact-content-container'>
            <div className='Contact-border-box'>
              <div className='Contact-content'>
                <h2 className='Contact-title title'>{COPY.title}</h2>
                <div className='Contact-group'>
                  <div className='Contact-row'>
                    <span className='Contact-icon material-icons-outlined md-36'>place</span>
                    <div>
                      <p className='Contact-subtitle'>{COPY.addressLabel}</p>
                      <p className='Contact-info'>{COPY.address1}</p>
                      <p className='Contact-info'>{COPY.address2}</p>
                    </div>
                  </div>
                </div>
                <div className='Contact-group'>
                  <div className='Contact-row'>
                    <i className='Contact-icon material-icons-outlined md-36 mail-icon'>mail</i>
                    <div>
                      <p className='Contact-subtitle'>{COPY.emailLabel}</p>
                      <p className='Contact-info'>{COPY.email}</p>
                    </div>
                  </div>
                </div>
                <div className='Contact-group'>
                  <div className='Contact-row'>
                    <i className='Contact-icon material-icons-outlined md-36'>phone</i>
                    <div>
                      <p className='Contact-subtitle'>{COPY.phoneLabel}</p>
                      <p className='Contact-info'>{COPY.phone}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <Quote /> */}
      </div>
    );
  }
}

const styles = {
  image: {
    backgroundImage: `url(${ContactImages.header})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
}