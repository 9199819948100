import React, {Component} from 'react';
import {NavLink} from 'react-router-dom';
import logo from '../../assets/logo_new_rustic.png';
import menuIcon from '../../assets/menu-icon.png';
import './Navbar.css';

export default class Navbar extends Component {
  state = {
    scrollPosition: 0,
    scrollDown: true,
  }

  componentDidMount() {
    window.addEventListener('scroll', this._onScroll, {passive: true});
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this._onScroll);
  }

  render() {
    const {toggleMenu} = this.props;
    const {scrollPosition, scrollDown} = this.state;
    return (
      <div>
        <div className={`Navbar Navbar-row ${scrollPosition === 0 ? '' : scrollDown ? 'Navbar-down' : 'Navbar-up'}`}>
          <NavLink exact to='/' className='Navbar-row'>
            <img className='Navbar-logo' src={logo} alt='logo' />
            <div className='column'>
              <p style={styles.honest} className='Navbar-name'>HONEST</p>
              <p style={styles.craftsmen} className='Navbar-name'>CRAFTSMEN™</p>
            </div>
          </NavLink>
          <div className='mobile-nav'>
            <img src={menuIcon} onClick={toggleMenu} alt='menu button' />
          </div>
          <div className='Navbar-row navbar'>
            <NavLink exact to='/' className='Navbar-link'>
              HOME
            </NavLink>
            <NavLink to='/about' className='Navbar-link'>
              ABOUT
            </NavLink>
            <NavLink to='/projects' className='Navbar-link'>
              PROJECTS
            </NavLink>
            <NavLink to='/contact' className='Navbar-link'>
              CONTACT
            </NavLink>
          </div>
        </div>
      </div>
    );
  }

  _onScroll = () => {
    const scrollPosition = window.pageYOffset;
    const scrollDown = this.state.scrollPosition < scrollPosition;
    this.setState({scrollDown, scrollPosition});
  }
}

const styles = {
  honest: {
    marginTop: -5,
    marginBottom: -18,
  },
  craftsmen: {
    marginTop: 0,
    marginBottom: -15,
  },
}