const CCB = '229455'

export const HOME = {
  quote: ['We want our clients', 'to be as proud of', 'our work as we are'],
  link: 'Find out about us',
  summary: 'At Honest Craftsmen, we believe that renovating or building your home should be an exciting experience, and it is our mission to alleviate as much stress from the process as possible. We believe that excellent customer service  is essential for a successful project, which is why we are changing what it means to be a contractor.',
}

export const ABOUT = {
  title: 'About us',
  intro: 'At Honest Craftsmen, we know that shopping for a good contractor can be difficult and overwhelming. We know what it’s like to worry about contractors not finishing their work, being there on time, or wrapping up a project and finding hidden fees. We believe that contractors should be held to a higher standard, which is why we are changing what it means to be a contractor.',
  intro2: 'We know that when you hire us, you are entrusting us with your home. This is a place that you find peace and comfort, a place where you build and spend time with your family, and a place where memories are made. These qualities are as important to us as they are to you, which is why it is so important to us to provide you the best experience possible. In order to achieve that, we have three core values of our company: Trust, Honesty, and Craftsmanship. ',
  trustTitle: 'Trust',
  trustText: 'We believe that the experience of building trust is a critical part of our process. In order to achieve that level of trust we provide our clients with clear expectations of their project, walk them through the entire process of their project, and provide world class level of customer service essential to the success of any great company.',
  honestyTitle: 'Honesty',
  honestyText: 'Our level of commitment to being honest to our clients is ingrained in our company name. We believe that transparency is a great way to build trust, which is why we will always strive to be as transparent as possible with our clients. You can be assured that we seek out as accurate information as possible and communicate that so that our clients know what we know.',
  craftTitle: 'Craftsmanship',
  craftText: 'Craftsmanship is something that is extremely important to us as a company, because every detail matters. It is our passion to do work as perfectly as possible because we take immense pride in our work and we want our clients to be just as proud of their home when we are finished.',
}

export const PROJECTS = {
  title: 'Our work. Our pride.',
  ramonaTitle: 'Modern Farmhouse',
  ramona: 'This home in the heart of Woodstock was completely remodeled inside and out. It was a very rough two bedroom, one bathroom with a non legal second floor. The main floor received an addition in the kitchen area with a large window to open the space up. The second floor was rebuilt with a much needed primary suite which changed the house to a 3 bedroom, 2 bathroom. Using wide plank wood flooring, black accents from the windows and handrails, custom built and painted custom cabinets, and a mixture of lap and board and batten siding, this house was transformed into a contemporary farmhouse that fit into the neighborhood, but also stood out among the rest of the homes.',
  neTitle: 'Updated Victorian',
  ne: 'Another very rough home, located just blocks from Alberta, was this diamond in the rough Victorian home. This house was a complete remodel from top to bottom. Doing our best to maintain it’s Victorian charm, the upper bedrooms were reimagined to create a primary suite, while maintaining two bedrooms on the upper floor. The doorway between the kitchen and dining room was converted to glass french doors to allow a more open flow to the main floor. The basement also received a major update, adding 650 square feet of living space including a bedroom, full bathroom + laundry, and living space. The house received updates in every aspect, which beautifully restored this classic Portland home.',
  chavezTitle: 'Mid-Century Modern',
  chavez: 'A Mid-Century time capsule, this home located blocks from Reed College was in dire need of an update. The kitchen was completely updated with beautiful cabinets, and a wall was removed to make space for an island and open concept. All bathrooms were remodeled with beautiful tile, new vanities, and fixtures. With new trim and floors throughout, this four bedroom, two and a half bathroom Mid-Century time capsule was refreshed and ready for a new family. ',
  litesTitle: 'Southern Lites',
  lites: 'This renovation included a complete gut of the kitchen, moving and reframing window locations, window install, siding, floor refinishing, new appliances, new custom cabinets, light fixtures, paint, new counters, and backsplash. ',
  windsorTitle: 'Windsor Terrace',
  windsor: 'This was a kitchen Refresh project. The scope of the project included renovating the floors, refinishing and painting the kitchen cabinets, and painting the interior of the house.',
  isleyTitle: 'SE Isley',
  isley: 'This was a major deck rebuild. The scope of work included demolition of the existing deck, framing and installing a new deck, removing old french doors and converting to a window, and building a new closet.',
  kitchenTitle: 'Kitchens',
  kitchen: 'The Kitchen is one of the most important rooms in a house. Not only is it a space where you prepare nourishment, it is a space for where you communicate and catch up, and a place you can find peace and comfort.',
  bathroomTitle: 'Bathrooms',
  bathroom: 'Perhaps the most important room in your house. The bathroom is where you start and end your days. It’s a place to collect yourself and a place to unwind.',
}

export const CONTACT = {
 title: 'Contact Us',
 addressLabel: 'ADDRESS',
 address1: '11801 SE Southern Lites Dr.',
 address2: 'Happy Valley, OR 97086',
 emailLabel: 'EMAIL',
 email: 'info@honestcraftsmen.com',
 phoneLabel: 'PHONE',
 phone: '503-679-4109 / 503-957-2372',
 ccbLabel: 'CCB#',
 ccb: CCB
}

export const QUOTE = {
  title: "Got a project? Let's get started."
}

export const FOOTER = {
  copyright: '©2021 Honest Craftsmen™, LLC',
  info: `CCB# ${CCB}`
}