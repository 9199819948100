import React, { Component } from 'react';
import {NavLink} from 'react-router-dom';
import closeIcon from '../../assets/close-icon.png';
import './Menu.css';

export default class Menu extends Component {
  render() {
    const {isVisible, toggleMenu} = this.props;
    return (
      <div className={`Menu ${isVisible ? 'show-menu' : ''}`}>
        <div className='Menu-icon-container'>
          <img src={closeIcon} onClick={toggleMenu} alt='close button' />
        </div>
        <div className='Menu-container'>
          <NavLink exact to='/' className='Navbar-link' onClick={toggleMenu}>
            <h3>HOME</h3>
          </NavLink>
          <NavLink to='/about' className='Navbar-link' onClick={toggleMenu}>
            <h3>ABOUT US</h3>
          </NavLink>
          <NavLink to='/projects' className='Navbar-link' onClick={toggleMenu}>
            <h3>PROJECTS</h3>
          </NavLink>
          <NavLink to='/contact' className='Navbar-link' onClick={toggleMenu}>
            <h3>CONTACT</h3>
          </NavLink>
          {/* <NavLink to='/quote' className='Navbar-link' onClick={toggleMenu}>
            <h1>QUOTE</h1>
          </NavLink> */}
        </div>
      </div>
    );
  }
}