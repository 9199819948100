import React, {Component} from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import {QUOTE as COPY} from '../../constants/copy';
import './Quote.css';

export default class Quote extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      phone: '',
      address: '',
      message: '',
      how: '',
      nameError: false,
      emailError: false,
      phoneError: false,
      addressError: false,
      messageError: false,
      howError: false,
      captcha: null,
    };
  }

  render() {
    const {nameError, emailError, phoneError, addressError, messageError, howError, name, email, phone, address, message, how, captcha} = this.state;
    const disabled = captcha === null;
    return (
      <div className='Quote'>
        <h2 className='title'>{COPY.title}</h2>
        <div className='Quote-content'>
          <form className='form' onSubmit={this._handleSubmit}>
            <input
              className='input form-box'
              ref='name'
              name='name'
              type='text'
              value={name}
              placeholder='Name*'
              onChange={this._handleChange} />
            <div className='error-container'>
              {nameError ? (
                <p className='error-message'>Name is required.</p>
              ) : null}
            </div>
            <input
              className='input form-box'
              ref='emailError'
              name='email'
              type='email'
              value={email}
              placeholder='Email*'
              onChange={this._handleChange} />
            <div className='error-container'>
              {emailError ? (
                <p className='error-message'>Valid email address is required.</p>
              ) : null}
            </div>
            <input
              className='input form-box'
              name='phone'
              type='text'
              value={phone}
              placeholder='Phone*'
              onChange={this._handleChange} />
            <div className='error-container'>
              {phoneError ? (
                <p className='error-message'>Phone number is required.</p>
              ) : null}
            </div>
            <input
              className='input form-box'
              name='address'
              type='text'
              value={address}
              placeholder='Address*'
              onChange={this._handleChange} />
            <div className='error-container'>
              {addressError ? (
                <p className='error-message'>Address is required.</p>
              ) : null}
            </div>
            <textarea
              className='input text-area form-box'
              name='message'
              type='text'
              value={message}
              placeholder='Tell us about your project*'
              onChange={this._handleChange} />
            <div className='error-container'>
              {messageError ? (
                <p className='error-message'>Message is required.</p>
              ) : null}
            </div>
            <div className='how-container'>
              <div className='row'>
                <p className='how-label'>How did you hear about us?</p>
                <div className='how-picker-container'>
                  <select name='how' className={`how-picker ${how === '' ? 'picker-placeholder' : ''}`} onChange={this._handleChange} value={how}>
                    <option className='picker-option' value=''>Select One*</option>
                    <option className='picker-option' value='friend'>Referral</option>
                    <option className='picker-option' value='web'>Web search</option>
                    <option className='picker-option' value='other'>Other</option>
                  </select>
                </div>
              </div>
              <div className='how-error-container'>
                {howError ? (
                  <p className='error-message how-error'>Required.</p>
                ) : null}
              </div>
            </div>
            <ReCAPTCHA
              sitekey='6LdTZB4aAAAAACZljokJTylZLxdB3IBho8mqIFZd'
              onChange={this._onRecaptcha}
            />
            <div className='align-right'>
              <button
                disabled={disabled}
                id='submit-button'
                className={`Quote-button outline-button ${disabled ? 'disabled-button' : 'black-button'}`}
                type='submit'
                value='submit'
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }

  _onRecaptcha = captcha => {
    console.log("Captcha value:", captcha);
    this.setState({captcha})
  }

  _handleChange = event => {
    const {value, name} = event.target;
    this.setState({[name]: value});
  }

  _handleSubmit = event => {
    event.preventDefault();
    this._validateForm();
  }

  _validateForm = () => {
    const {name, email, phone, message, address, how} = this.state;
    this.setState({
      nameError: this._validateInput(name),
      emailError: this._validateEmail(email),
      phoneError: this._validateInput(phone),
      messageError: this._validateInput(message),
      addressError: this._validateInput(address),
      howError: this._validateInput(how),
    }, this._sendEmail);
  }

  _validateInput = input => {
    return input.trim() === '';
  }

  _validateEmail = email => {
    const re = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
    return !re.test(email);
  }

  _sendEmail = () => {
    const {nameError, emailError, phoneError, messageError, addressError, howError} = this.state;
    if (!nameError && !emailError && !phoneError && !messageError && !addressError && !howError) {
      // verify Google captcha
      // then send email to info@honestcraftsmen.com
    }
  }
}