import React, {Component} from 'react';
import {NavLink} from 'react-router-dom';
import smoothscroll from 'smoothscroll-polyfill';
import Quote from '../../components/Quote/Quote';
import './Home.css';
import {HomeImages} from '../../assets/images';
import {HOME as COPY} from '../../constants/copy';
import bedroom from '../../assets/images/home/bedroom.jpg';
import dining from '../../assets/images/home/dining.jpg';
import living from '../../assets/images/home/living.jpg';
import living2 from '../../assets/images/home/living2.jpg';
import primary from '../../assets/images/home/master.jpg';

const images = [living, living2, bedroom, dining, primary];

export default class Projects extends Component {
  constructor(props) {
    super(props);
    this.state = {
      photoIndex: 0,
    };

    // polyfill for smooth scrolling on Safari
    smoothscroll.polyfill();
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.interval = setInterval(() => {
      let newIndex = this.state.photoIndex === (images.length - 1) ? 0 : this.state.photoIndex + 1     
      this.setState({photoIndex: newIndex});
    }, 4000)
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    return (
      <div className='Home'>
        <img src={bedroom} style={{display: 'none'}} alt='bedroom' />
        <img src={dining} style={{display: 'none'}} alt='dining' />
        <img src={living} style={{display: 'none'}} alt='living' />
        <img src={living2} style={{display: 'none'}} alt='living2' />
        <img src={primary} style={{display: 'none'}} alt='primary' />
        <div
          className='Home-img'
          style={{backgroundImage: `url(${images[this.state.photoIndex]})`}}
        />
        <div ref={ref => this.introRef = ref} className='Home-container'>
          <div className='Home-text-container'>
            <h2 className='Home-title title'>Residential Contractor Serving the Greater Portland Metro Area</h2>
            <p className='Home-text'>{COPY.summary}</p>
          </div>
          <div className='Home-tiles'>
            <NavLink className='Home-nav-link' exact to='/about'>
              <div className='Home-tile Home-about' style={styles.aboutImg}>
                <div className='img-overlay'>
                  <p className='Home-tile-text subtitle'>About</p>
                </div>
              </div>
            </NavLink>
            <NavLink className='Home-nav-link' exact to='/projects'>
              <div className='Home-tile Home-projects' style={styles.projectsImg}>
                <div className='img-overlay'>
                  <p className='Home-tile-text subtitle'>Projects</p>
                </div>
              </div>
            </NavLink> 
          </div>
        </div>
        {/* <Quote /> */}
      </div>
    );
  }

  _scrollDown = () => {
    this.introRef.scrollIntoView({behavior: 'smooth'});
  }
}

const styles = {
  aboutImg: {
    backgroundImage: `url(${HomeImages.about})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  projectsImg: {
    backgroundImage: `url(${HomeImages.projects})`,
    backgroundSize: 'cover',
    backgroundPosition: 'top',
  },
}