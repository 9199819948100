import React, {Component} from 'react';
import {AboutImages} from '../../assets/images';
import {ABOUT as COPY} from '../../constants/copy';
import './About.css';

export default class About extends Component {

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className='About'>
        <div className='About-intro-row About-row'>
          <div className='About-intro-img About-img' style={styles.headerImg} />
          <div className='About-intro-text About-text-container'>
            <h2 className='About-title title'>{COPY.title}</h2>
            <p>{COPY.intro}</p>
            <p>{COPY.intro2}</p>
          </div>
        </div>
        <div className='About-trust-row About-row'>
          <div className='About-trust-img About-img' style={styles.trustImg} />
          <div className='About-trust-text About-text-container'>
            <h2 className='About-title title'>{COPY.trustTitle}</h2>
            <p>{COPY.trustText}</p>
          </div>
        </div>
        <div className='About-honesty-row About-row'>
          <div className='About-honesty-img About-img' style={styles.honestyImg} />
          <div className='About-honesty-text About-text-container'>
            <h2 className='About-title title'>{COPY.honestyTitle}</h2>
            <p>{COPY.honestyText}</p>
          </div>
        </div>
        <div className='About-craft-row About-row'>
          <div className='About-craft-img About-img' style={styles.craftsmanshipImg} />
          <div className='About-craft-text About-text-container'>
            <h2 className='About-title title'>{COPY.craftTitle}</h2>
            <p>{COPY.craftText}</p>
          </div>
        </div>
      </div>
    );
  }
}

const styles = {
  headerImg: {
    backgroundImage: `url(${AboutImages.header})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  trustImg: {
    background: `url(${AboutImages.trust})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  honestyImg: {
    background: `url(${AboutImages.honesty})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  craftsmanshipImg: {
    background: `url(${AboutImages.craftsmanship})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
}