export const HomeImages = {
  carousel: ['living.jpg', 'living2.jpg', 'bedroom.jpg', 'dining.jpg', 'master.jpg'],
  about: 'https://lh3.googleusercontent.com/69Rshp2VsVyRcMc4wicNovQhdMxcbVHvRGTALh8hBw9XdzD2ZBxRx0W3kgL25ockIu7OyTjzg-WB1FaGCtR0Rbp8Xa-jwd_xG31NLHF-1jqMMEXzPQf4oWrwV1z2R37CemOwSlMBkBGM9gRTjktPUJfqWi3ayO_lgi_NBCogoB7rKURr-RIWIppjbkyZkQoeRf2KaB1RA3UcLrUCyewgBbun5odiFEeKNr1Zo0AR5w6BbeuY-HYMXpCgoAxuUOfomHcdAb0uzyUpYMbn_Me1gBDSGfKUZZNXvImcf3ozyZQOPgLYvy1z6thfrWkdkpLxLswHQfrPkJpHWucuPRq2iRoss8aulYWcuC5cqjP8kaOMZlR4hhEmrI4kogfgNnQ5as7lMoJbqUqlkDQkAuiJOKSkvUjpfVCz8_7aR1uy1YTZKnqDAOBDvfCEF_nMYQPMQ2NwdOng58u_kxCPcntpny8bB69HdCV_2bA5D269Blr5dM11hxE-jtmWxrpVhW5yRa5egDPIJIn0Yaz3VuXTMHlupUDc1LXwsXibVvfEA9Ck2wpFnGvv02zFBU-SCOL60dVCh_klj5BWEOC5mEyycMcCeFYKrZtJs6sSSdvIQF7mD1lzvIT6McvnUQHuWlKJlKWKCiMfZnJBm7jBXjXbQoWf1uAm30w95Kq4ms5lX1e5ufQda0eF4PruxPBCwIHBXhdcvmgye14N6NMf3jPiqUU=w1920',
  projects: 'https://lh3.googleusercontent.com/DlTcu20R2Q3PwgLxConM_BqWvhJBv9CWwCNH1DaLq2xNQlBbSGZnoaRJNcjkwrjrOicXdwpbPUBV0QDliIfkLqltwftDa_rDjnnolKDJzbeVdEqud81pXHZAropJeSkiwRHG3_rBaGPs9cHqDZAFLygkg2WjnpMm02OU0FWpt50FADjSw3tOJStTIRER4H7mw6lKN1aRXjJvQmHGQZaisf8cxpcm-9D7X8x7D9WRBe_JCBHBAK1158GZaRD5n3mu5MBwNjuEZsFhYBKdHvCrRAf_eXKgqDlURu8A0LnRxz7IWEZk5XcQYEGJXG-btOtSWXs8vAWD1PewmgtImKIyXcxhqtXVEgJ8HfYcHIQ2BZuHMcyrFjxdz2EeaivivtTwAfhthNEOVluyNGwHxLWSqqk4e2ebl4MvnB1L1YKasxhXUTnHSszCbu24bl1VTLkCSRN8hXI24Kt19FAdt2T_pyFIqtSoKF0BQ6qhcQbg3v20CIONoYv8EMvsCI-saL4Dcbjn3VXZek2vcFF5earmDKIdxBkqAIDzhZbypBIJQaNT1-hKw5_0iFeo1Dtu7877BwB0Nip0k3VavPiwdp8i2nhQ6aoaw_R4WOFs2bPbaEce7G7avSRnBPKP72f3SKOI614vcF5UoFwzNnAgpFyl3mSV0uMGxVASlMtM-fOGVqFNlKcSOCUksWZlT8uNrJzV2x_YRf3-O8fb1os87NYiyqw=w1920',
}

export const ProjectsImages = {
  header: 'https://lh3.googleusercontent.com/H-6iTLPLe0zIdjDoGJRlboJaGqnS1ka06_BKRKqkKyxpu-5CqMfnqHIbGz68nOnCJkv_IVq2ULnBkOebKylQnjeXdTUHmypsERUUBmdWQaVAg7wUMveW27mXQrAB_vNTzGI_TaPxS6SmRSThvaG8QKTK7K7Xos7IksvPKXnzr6puIvPNRxD9cfpdXhmkaX-XR0N4jALe5LDoIEK5nsC3R8l0g6ND8jXI8akVYhZkqVwKhU5kkgXREw8AJ3PnNqTENBCYs0QL4lsyhjCDwao3St0ojCdSievusVsac_bmlttzgJ1E5mXl3EJXvMvgiM_f_MyZcZOlNg-dX8XYVhpHoiZNKiyNOq806pzq5276UnSC_VYqklPaXHnIoxcBdSggVwS_rXjxdmi6dF6KLBWfMhUiOy5xvXiTb6g9voaWgNoL9i4mIyAnLIDxbd7oq6WKBQU09hob1MBiw3qVzoP9vnWZasRcGJMAGuudhBJSdx_k3ozv9n6LYvW1Yv6-eRsEA-4E12CbBHvNW0sPclsuggF0cmzhdBqLArZMtdPdAdqsq1Ev2t43skaj-0t2IVRhtesRRvJNIakjnbZ9iJRskdeDwMEEihq-oYaH--8d79GwCtUtd1swcKN51bLZZHRWrLWQklW6Q1lZVJCCQiBda7-YIdGaGkQgjcsGBhetQCRp4PZ-0fosK2bkUabvmMLcRHGTxUzM72LPuuh_raO93KM=w1920',
}

export const ContactImages = {
  header: 'https://lh3.googleusercontent.com/wDGyPCyqvIoOupH7DC5i6A64jEWsMXfww7yzLa1ll5dmjySBlwIrcP3J0lLMn17DpvqP4UFjvlYQ0pJJWoEbW3KQwtJ-h7zgMW_qacxsYyeq69W7Xi_c-FHogGwkiezDw6g_D8Q1rMtUBCqIGn7L41P-Cjw4YahbGwfAqaSmczPm6VjkYLTo2Q9gnS9sRYgmihZ4Q4cpRFnqqGv3ErvL0V95EMPwGtDdt4drEHAUOab68CaTElp5HViv7UmfwVowWehKleDtHymXtdL4ElhfjyuJbZf16UuLispUiVkkVrpX-NLajxeDkd-U4c4lVjg-nBkSg2IJB7cTllbI2_Qdkg18wVuxkwW1YSFqaPLbNIpEHtz2ka-GyhMbJDABBCZ4yA94b9CbfWN-HimBH7oolrU-tx3kwTpE4S_hx9h7YUkK4VoIRtb6n2_gYXcgwYfA1Gg-AWgc-bs2xMWzido0wf_q5dL8sR3vf0O2NySxMJR4jH31lxms_QZMmwb-usHODuPNodDWK-8d3k3R-Si6d7TOTGEGgjHyEoN5d-TuSXbeCc15QczaHzcO8SvXSK3pc3uWNsUInJqG4JYD3Vz79JYme650XZ5qxddccP6PpAWmmzB6VMotp6BehtfzooejFsqrYPxHaSgj8O5Q49QObc5yf7LaY9ygtFoFjkGdWagB7yNdBH0S_ORSF0_JGkuXKtBfkjlb-SsLDDkw3mz1qsw=w1920',
}

export const AboutImages = {
  header: 'https://lh3.googleusercontent.com/_BA3EqMP07bHmntrOa8VYjrjMEvsJXQF2b3SoJFUUst_mBuFZAYcwOtzXBnVwPaEbSxpBo9xHDPfG6Mq0YpOBRMH0Ne_BImAY5OcTgDBLpUoV4-IAXqJcTM7O4hOjc3GwfjSqH-4SY-iEXH9FGGBqgyiJwTvT5-oGJtqlX7OEDEpZXwcYCOPOKnZphkMfxFLUNRohF5nlS6VAUCf0V8yNqvEozxgC6rtug61Z_MCiRiWNRQARuzfbxbwvhUxXnSKhuEr9UTkFrl8db5mDn6LCehtowUrvgK7kfHlYjaMzjsX3tscvIlZWggYTJFxL544L-GHbGJ2cbG56ukpogl46GMN7Y7nfNEVAKFWd5umMmemlDOHWBryVNcyKyiUEcY25hshhAgtA9HgcxU3X_YmT-PZVxOunZHvvEfYdKVnc4C-xmImBrr_kLpjZna6qKWyXIFoHg7sPPZWtOSH0EieXRu5S1xCERT06QjLLhPT4ChO6bHHUHHzdu3us0w2axw3qzkaIsLuLKRgsKiNVclxnFElNzKZnIIhpq_xyUqzGpblU8VjhTHPv6DCKJ2c3fK4Lui0xwc-EwI7A_K07IyGMK_LuN9hD_4Lo_Ui9ImaT-cI25GTfAT-GIIXwMc1T8Ink4IcxOK2F9wcS1nuU_WYYRnAfb-QGMy_BxpHcFaW8YZTFL_mwpkYClKXXYfaqe9FU4SbRX1mRSGRuDuojUJimH0=w1024',
  trust: 'https://lh3.googleusercontent.com/JTQjPecrj15xUfKqXWl3M4Begaebm0uVDs7MIqTOv1TSA_sJO5bDpt6HDQ-Zl_Op_WJWv_uZm0if4uwq11eu60uLUsVuIkrohwmjXAYeUX7orsyKKWnhw7c24Kjpk4itIwhd-oEYcSqT5A6k9J7kg7CEBY104_ZEEozcjI_SYqSGLzsoG9wTlqFetm0PDhzALeCZfoKZjqfz6U3-sYODHkmMotqRuRCoPt0oHO7Lj4td_2DboqLc0mt9jSpQdSVzr-Lz_u_Nh6cdakR1hy6RYcRxbiLKUKPSeh2MLi-OQlhOaa5X_hwr5zKCpKjodYYdMbusbuyM8fC0lY7xzWOV-rwvlZEe4Je037vzjkxDtxM-7umNxQxsvW8AXvuCREuOg_CqFgIi8xHhQComOwzPYTjtsjOV9DvplLPEkXSnMyH7NXqdxjp1Gm2N-4vixpAHzkZLwwjrrIqcPe72dP8Vc_yCh6GQYfxs9mApqEcx1Ksed9ZTGAmjhI96X_htRWg5IYdkvEVPcUWQoTYg6f7JYe7w8CdOeFE1hhw3r4rosmAvJigRK7LUniety1k1NRpl2STWXGUhZbSoZu3qTVPNDwROlDhtT-J8VWtX7gsngaUkvPsP2pu76anxKDgVB_ev7KADjhu7aZaozuCGtLKLqFX51NfD9uNYfzJ62KL7msS-foJuHltrk2pUmz4TJwpEgQv4rk3oiLIEE-YudhKnYIA=w1024',
  honesty: 'https://lh3.googleusercontent.com/RJASKEZrP8dOc8QiZ0fQxVgcTBWlsYCS8Wj4giSuLBENjtZ10gIwRpEWQ5lPuZ0r6RjfF2XBhA355bg1nFopuWBn9C9zLcCAQtBVJGJ9amVAUrPzzu1d3HKnh1mZ1yR2yR1-82KDwF6CjY0iZgi0EwZ1eBlGF2yAd2t9X6KZNjPvP17LxPil9SB2QjtkmVQnXt0N5-Mlaa_sMdK5euAwkAucI20s5dOzfwpCCVHRcxCzhYHHotCBQ3eo-IVJ7yYgDGCAOGt2m0hs3_O87rQ2vtXC2yg4xwc65j7SZQlj4R-8aQin34O9OzNH5RYonGrgWoV3STxj-1UDyTz0sTKAupothMuKmILiZaexahYtR4Jo3Z-iVLVzIxI7OlbuODmtTyEKajZFDN27-m8le1-FCTbH6KW4VMFZvVVK5DCDCIEEDyFhUwG6PToo7OgSOzbvW2KF0RmuuDahtuT5ktbdPY4oUItf4FfScUIA3hwOIgtJAiRX6Y7ZA7y5UEwPPWUs8jDmKxHa5l4gtzbb2xLfuimLTKa25z5ZPI2PWV5iYeyWjck_HUEXBJLWAV1Mb0l2et-BZYXL1TsWJ5p-ZiENzLncZhhQ9aRWkXABQN-W7nFSygBCfMsqf-n85GoFmQhTXqeJn0bOXibLobZYkvHT-vFDFpbHOPEGbEvXikDXo2R9hUCyOr5tYBFWUCRKyimWpxjr2xfC6zWnYd3MDvo9TmI=w1024',
  craftsmanship: 'https://lh3.googleusercontent.com/fCKp9vwo31i80vYq_0IChC2CSy0OqYaa0O-YnYHOvEbsW9WG-Jt_nmCXYvK-7TiqhbuaJIH_hdg5T1hASFAxe81XqIiANa9GP09GJ2VHzlb55S_USJ6fLSM6E5fnr8zON6f8lpL-b54Y2h-tHwppF8-j-6fUeNJEIse9JFP0hv3KX_mvIMrr1nMxCeAK4_4f3FIVsVG-3SIggfxkSu1jPdPZuM2FyngidRkXF6He8bNLQdvZbg_U5u6DM_jUZ957r-rndiPkBqOJDBwjzFCyBGKd9XIVTCzzGLWCY4Zxp9M-EK_OZgMg-LgUaTetjborfq0R80luJbX6cCy3tZVGpKUeR2HqS7J4TXw-pWjZnjC0RuG9HaPozfiRbY_EYn5nvtpNhAxnu-wnfAq7ozEl72IWAj9bECcTQFxpVJnTPB1OKn4PD5bfHcX9n4LM3bZ5SGjEDWrRP1vAqTlg1mklGv9LSW2J59gprUUOjS9Wt_rfE4-Ue-YFWna-H68j1rxb_V65sinzcClCh1YkjLPr8WyqyTONVfRWf0ZNjo4ZIyLd3wFGk7qbm-W3PsO7qCboz61VqdR3DikHF6g312mXTOOpnfkqSYSlyGXbb2Wb3MpP2A6XdggHYDKAcAsmFPDJqZCPTU7fkErfE6hy0g9BSF4cZktvfCic_c4AuISSPZUvcOnCgHuZYXJbu1F7bQWtunL1OmIoGjw2RhKLZOGY0uI=w1024',
}