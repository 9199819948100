import React, {Component} from 'react';
import {Switch, Route, withRouter} from 'react-router-dom';
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';
import Menu from './components/Menu/Menu';
import {Home, About, Contact, Projects} from './routes';
import './App.css';

class App extends Component {
  state = {
    isMenuVisible: false,
  }

  render() {
    const {isMenuVisible} = this.state;
    return (
      <div className={`App ${isMenuVisible ? 'no-scroll' : ''}`}>
        <Menu isVisible={isMenuVisible} toggleMenu={this._toggleMenu} />
        <Navbar toggleMenu={this._toggleMenu} />
        <Switch>
          <Route path='/projects'>
            <Projects />
          </Route>
          <Route path='/about'>
            <About />
          </Route>
          <Route path='/contact'>
            <Contact />
          </Route>
          {/* Important: A route with path='/' will *always* match
              the URL because all URLs begin with a /. So that's
              why we put this one last of all */}
          <Route path='/'>
            <Home />
          </Route>
        </Switch>
        <Footer />
      </div>
    );
  }
  
  _toggleMenu = () => {
    this.setState({isMenuVisible: !this.state.isMenuVisible});
  }
}


export default withRouter(props => <App {...props} />)